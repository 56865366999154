<template>
  <div class="order">
    <!-- <el-input
      v-model="kw"
      class="order-search"
      placeholder="请输入"
      :suffix-icon="Search"
      size="large"
    /> -->
    <el-card class="order-container">
      <div class="order-list">
        <el-card v-for="(item, idx) in datas" :key="idx" class="order-card">
          <template #header>
            <div class="order-card-header">
              <span>订单编号：{{ item.order_sn }}</span>
              <router-link class="order-card-btn" :to="{
                name: 'order-detail',
                query: {
                  order_sn: item.order_sn
                }
              }">订单详情
                <el-icon>
                  <ArrowRightBold />
                </el-icon>
                <el-icon>
                  <ArrowRightBold />
                </el-icon>
              </router-link>
            </div>
          </template>
          <div class="order-table">
            <div class="order-tr">
              <Good v-for="(cell, index) in item.order_goods_items" :key="index" :item="cell" />
            </div>
            <!-- <div class="order-tr order-price-container">
              <div class="order-time">{{ item.has_quote == 1 ? priceFormat(item.order_amount, item.currency) : '-' }}</div>
            </div> -->
            <div class="order-tr order-status-container">
              <div class="order-status">
                <template v-if="item.has_quote == 1">
                  <span style="color: green;">已报价</span>
                </template>
                <template v-else>
                  <span style="color: #f00;">待报价</span>
                </template>
              </div>
            </div>
            <div class="order-tr order-tr-1">
              <div class="order-time">{{ item.created_at }}</div>
            </div>
          </div>
        </el-card>
      </div>
    </el-card>
    <el-pagination v-if="sourceData.totalCount" v-model:currentPage="currentPage1" :page-size="pageSize"
      :background="true" layout="total, prev, pager, next" :total="sourceData.totalCount"
      @current-change="handleCurrentChange" />
  </div>
</template>

<script setup>
import { ref, onUnmounted, watch } from 'vue'
import { orderInfoList } from '@/api/order.js'
import { ArrowRightBold, Search } from '@element-plus/icons-vue'
import Good from './components/Good.vue'
import currencyFormatter from '@/utils/currencyFormatter'

const currentPage1 = ref(1)
const datas = ref([])
const sourceData = ref({})
const pageSize = ref(20)
const kw = ref('')
const timer = ref(null)
const priceFormat = (price, currency = 'CNY') => {
  return currencyFormatter.format(price, { currency: currency})
}
const init = (page = 1, kw) => {
  orderInfoList({
    page,
    pageSize: pageSize.value,
    kw
  }).then((res) => {
    const { error, data } = res
    if (error == 0) {
      sourceData.value = data
      const { items, page } = data
      currentPage1.value = page
      datas.value = items
    }
  })
}
init()
const handleCurrentChange = (e) => {
  init(e)
}
watch(kw, (e) => {
  clearTimeout(timer.value)
  timer.value = setTimeout(() => {
    init(1, e)
  }, 300)
})
onUnmounted(() => {
  clearTimeout(timer.value)
})
</script>

<style lang="scss" scoped>
.order {
  position: relative;

  &-search {
    right: 0;
    width: 300px;
    position: absolute;
    bottom: 100%;
    transform: translate(0, -12px);
  }

  &-container {
    height: 800px;
    margin-bottom: 20px;

    :deep(.el-card__body) {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &-card {
    margin-bottom: 20px;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #eee;
      margin: -20px;
      padding: 15px 20px;
      font-size: 18px;
    }

    &-btn {
      display: flex;
      align-items: center;
      font-size: 18px;
      white-space: nowrap;
      color: var(--el-color-primary);

      :deep(.el-icon) {
        &:last-of-type {
          margin-left: -10px;
        }
      }
    }
  }

  &-table {
    margin: -20px;
    display: flex;
  }

  &-tr {
    border-right: 1px solid #eee;
    width: 65%;
    font-size: 18px;
    color: #999;

    &.order-price-container {
      width: 10%;
      // flex: 1;
      padding: 15px 20px;
    }

    &.order-status-container {
      width: 10%;
      // flex: 1;
      padding: 15px 20px;
    }

    &-1 {
      width: 25%;
      flex: 1;
      padding: 15px 20px;
    }
  }

  &-time {
    text-align: right;
  }
}

.el-pagination {
  justify-content: flex-end;
}
</style>
